import { Heading, Picture } from '@troon/ui';
import { NoHydration } from 'solid-js/web';
import { getConfigValue } from '../../../modules/config';
import type { RouteDefinition } from './_types';

export default function AdvisoryServicesPage() {
	return (
		<>
			<NoHydration>
				<Heading as="h2">Real World Challenges. Proven Solutions.</Heading>

				<p>
					As a division of Troon®, True Club Solutions (TCS) offers clubs and daily fee/resort destinations short-term
					advisory services. As an alternative to Troon’s professional management solutions, TCS engagements benefit our
					clients by tapping into the depth and breadth of expertise of our thousands of corporate and field associates
					to focus on club-specific challenges and opportunities. The myriad of services offered by TCS are customized
					to assist in solving specific facility problems through initial due diligence reporting and continual
					guidance.
				</p>

				<Picture
					src={`${getConfigValue('IMAGE_HOST')}/web/logos/true-club-solutions.jpg`}
					width={600}
					height={400}
					sizes="50rem"
					mode="contain"
					crop="center"
					alt="True Club Solutions logo"
					class="mx-auto"
				/>
			</NoHydration>
		</>
	);
}

export const route = {
	info: {
		nav: { hero: true },
		title: 'Advisory Services',
		description: 'An alternative to professional management solutions.',
		hero: `${getConfigValue('IMAGE_HOST')}/web/hero/advisory-services.jpg`,
	},
} satisfies RouteDefinition;
